import React, { useContext, useEffect, useState } from 'react';
import { DarkModeContext } from '../../context/DarkModeContext';
import { FacturacionContext } from '../../context/FacturacionContext'; // Importar contexto
import TituloPagina from '../../components/TituloPagina';
import BienvenidaZocoConect from '../../components/ZocoConect/BienvenidoPanelZocoConect';
import Select from 'react-select';
import DatePicker from 'react-datepicker'; // Asegúrate de que está instalado e importado
import 'react-datepicker/dist/react-datepicker.css';
import { Col, Form, Row } from 'react-bootstrap';
import ContenidoInicio from '../../components/ZocoConect/ContenidoInicio';
import Footer from '../../components/Footer';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import ProgressBarWrapper from '../../components/ZocoConect/ProgressBarWrapper';
import logo from "../../assets/img/logo.png";
import {
    faCircleArrowDown,
    faCircleArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Inicio = () => {
    const { darkMode } = useContext(DarkModeContext);
    const apiUrlToken = process.env.REACT_APP_API_TOKEN;
    const navegacion = useNavigate();
    const [cargando, setCargando] = useState(false);
    const [resultadosPorMes, setResultadosPorMes] = useState([]);


    useEffect(() => {
        const fetchData = async () => {
            setCargando(true);
            const token = sessionStorage.getItem('token'); // Obtener el token de sessionStorage

            if (!token) {
                Swal.fire('Error', 'El token no está disponible', 'error');
                setCargando(false);
                return;
            }

            try {
                const response = await fetch('/api/zococonect/obtenerzococonect', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${apiUrlToken}`
                    },
                    body: JSON.stringify({ Token: token })
                });

                if (response.ok) {
                    const data = await response.json();
                    setResultadosPorMes(data);
                } else {
                    const errorText = await response.text();
                    Swal.fire('Error', errorText, 'error');
                }
            } catch (error) {
                Swal.fire('Error', 'Hubo un problema al realizar la consulta', 'error');
            } finally {
                setCargando(false);
            }
        };

        fetchData();
    }, [apiUrlToken]);

    return (
        <>
            <div className='pt-5'>
                <BienvenidaZocoConect />
            </div>

            {/* {cargando ? (
                <p>Cargando...</p>
            ) : (
                <div>
                    <h2>Resultados por Mes</h2>
                    <ul>
                        {resultadosPorMes.map((resultado, index) => (
                            <li key={index}>
                                <strong>CUIT:</strong> {resultado.cuit} <br />
                                <strong>Nombre:</strong> {resultado.nombre} <br />
                                <strong>Mes:</strong> {resultado.mes} <br />
                                <strong>Total Bruto:</strong> {resultado.totalBruto}<br />
                                <strong>Comision:</strong> {resultado.comision}

                            </li>
                        ))}
                    </ul>
                </div>
            )} */}
            {cargando?(
                <p>Cargando...</p>
            ):(
                <div className='pt-5'>
                    <ContenidoInicio resultadosPorMes={resultadosPorMes}/>
                    <ProgressBarWrapper resultadosPorMes={resultadosPorMes}/>

                </div>
                
            )}
           

            <div className='pt-5'>
                <Footer />
            </div>
        </>
    );
};

export default Inicio;
