import React, { useState, useContext } from "react";
import { Row, Col, Card, Carousel } from "react-bootstrap";
import { DarkModeContext } from "../../context/DarkModeContext";
import "./ContenidoInicio.css";

const ContenidoInicio = ({ resultadosPorMes }) => {
  const { darkMode } = useContext(DarkModeContext);
  const [isPaused, setIsPaused] = useState(false);
  

  // Agrupar los resultados en conjuntos de 3 sin duplicación
  const groupResults = (results) => {
    const grouped = [];
    for (let i = 0; i < results.length; i += 3) {
      grouped.push(results.slice(i, i + 3));
    }
    return grouped;
  };

  const groupedResultados = groupResults(resultadosPorMes);

  const handleCardClick = () => {
    setIsPaused(!isPaused);
  };

  return (
    <div
      className="container"
      style={{ marginTop: "20px", marginBottom: "20px" }}
    >
      <Carousel
        controls={false}
        indicators={false}
        pause={isPaused ? "hover" : false}
        interval={isPaused ? null : 3000}
      >
        {groupedResultados.map((grupo, index) => (
          <Carousel.Item key={index}>
            <Row className="custom-row d-flex justify-content-center">
              {grupo.map((resultado, idx) => (
                <Col
                  key={idx}
                  xs={12}
                  md={4}
                  className="d-flex justify-content-center"
                >
                  <Card
                    className="h-100 card"
                    style={{
                      borderRadius: "28px",
                      cursor: "pointer",
                      width: "18rem",
                    }}
                    onClick={handleCardClick}
                  >
                    <Card.Body>
                      <Card.Title
                        className={
                          darkMode
                            ? "fs-17 lato-bold py-4 prority-4 text-white"
                            : "fs-17 lato-bold py-4 prority-4"
                        }
                      >
                        CUIT/CUIL: {resultado.cuit}
                      </Card.Title>
                      <Card.Text>
                        <strong
                          className={
                            darkMode
                              ? "fs-14 lato-regular py-4 prority-4 text-white"
                              : "fs-14 lato-regular py-4 prority-4"
                          }
                        >
                          Nombre:{" "}
                        </strong>{" "}
                        {resultado.nombre} <br />
                        <strong
                          className={
                            darkMode
                              ? "fs-14 lato-regular py-4 prority-4 text-white"
                              : "fs-14 lato-regular py-4 prority-4"
                          }
                        >
                          Mes:{" "}
                        </strong>{" "}
                        {resultado.mes} <br />
                        <strong
                          className={
                            darkMode
                              ? "fs-14 lato-regular py-4 prority-4 text-white"
                              : "fs-14 lato-regular py-4 prority-4"
                          }
                        >
                          Total Bruto:{" "}
                        </strong>{" "}
                        {resultado.totalBruto} <br />
                        <strong
                          className={
                            darkMode
                              ? "fs-14 lato-regular py-4 prority-4 text-white"
                              : "fs-14 lato-regular py-4 prority-4"
                          }
                        >
                          Comision:{" "}
                        </strong>{" "}
                        {resultado.comision} <br />
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default ContenidoInicio;
