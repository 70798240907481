import React from 'react';
import TablaUsuariosEmpleados from './TablaUsuariosEmpleados';

const BlanqueoEmpleados = () => {
    return (
        <section>
            <TablaUsuariosEmpleados />
        </section>
    );
};

export default BlanqueoEmpleados;