import React, { useContext } from 'react';
import { Bar } from 'react-chartjs-2';
import { DarkModeContext } from '../context/DarkModeContext';
import Chart from 'chart.js/auto';

const GrafLineasTerminales = ({ data }) => {
    const darkMode = useContext(DarkModeContext);
    const meses = data?.nombresMeses?.length > 0 ? data.nombresMeses : ['N/A'];
    const brutos = data?.totalesBruto?.length > 0 ? data.totalesBruto : [0];
    const brutoMenosInflacion = data?.brutoMenosInflacion?.length > 0 ? data.brutoMenosInflacion : [0];
    const inflacionMensual = data?.inflacionPorMes?.length > 0 ? data.inflacionPorMes : [0];



    const chartData = {
        labels: 
            meses
        ,
        datasets: [
            {
                label: 'Bruto',
                data: brutos,
                backgroundColor: '#4CAF50', // Verde
                hoverBackgroundColor: '#45A049',
                barThickness: 20,
                type: 'bar',
                yAxisID: 'y', // Asignar al eje principal
                order: 2,
            },
            {
                label: 'Bruto menos Inflación',
                data: brutoMenosInflacion,
                backgroundColor: '#FF7043', // Naranja
                hoverBackgroundColor: '#FF5722',
                barThickness: 20,
                type: 'bar',
                yAxisID: 'y', // Asignar al eje principal
                order: 3,
            },
            {
                label: 'Inflación Mensual',
                data: inflacionMensual,
                borderColor: '#FFD700', // Amarillo
                backgroundColor: '#FFD700', // Transparente
                borderWidth: 2,
                pointBackgroundColor: '#E89F2F',
                fill: false, // No rellenar bajo la línea
                type: 'line',
                tension: 0.4, // Suavizar la línea
                yAxisID: 'y1', // Asignar al eje secundario
                order: 1,
            },],
        
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        cutout: '60%',  
        plugins: {
            legend: {
                display: true,  // Ocultar la leyenda
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        const value = tooltipItem.raw || 0;
                        if (tooltipItem.datasetIndex === 2) {
                            // Inflación Mensual
                            return `${value}%`;
                        }
                        return `$${value.toLocaleString()}`;
                    }
                }
            },
            datalabels: {
                label: function (tooltipItem) {
                    const value = tooltipItem.raw || 0;
                    if (tooltipItem.datasetIndex === 2) {
                        // Inflación Mensual
                        return `${value}%`;
                    }
                    return `$${value.toLocaleString()}`;
                },
                display: true
            }
        },
        scales: {
            x: {
                ticks: {
                    autoSkip: false,
                },
            },
            y: {
                type: 'linear',
                display: true,
                position: 'left',
                title: {
                    display: true,
                    text: 'Valores (en millones de $)',
                },
            },
            y1: {
                type: 'linear',
                display: true,
                position: 'right',
                title: {
                    display: true,
                    text: 'Inflación (%)',
                },
                grid: {
                    drawOnChartArea: false, // Evitar solapar las líneas de grid
                },
            },
        },
        interaction: {
            mode: 'index',
            intersect: false, // Mostrar tooltips de todos los conjuntos en el eje
        },
        
    };

    return (
        <>
            <h6 className="text-center lato-bold fs-17" style={{marginTop: "20px"}}>Evolución Terminales</h6>
             {/* Leyenda personalizada */}
             {/* <div className="text-center mt-4">
                <div style={{ display: 'inline-block', marginRight: '20px', alignItems: 'center' }}>
                    <span style={{ backgroundColor: '#4CAF50', display: 'inline-block', width: '15px', height: '15px', marginRight: '5px', borderRadius: '50%' }}></span>
                    <span className='lato-regular'>Bruto</span>
                </div>
                <div style={{ display: 'inline-block', marginRight: '20px', alignItems: 'center' }}>
                    <span style={{ backgroundColor: '#FF7043', display: 'inline-block', width: '15px', height: '15px', marginRight: '5px', borderRadius: '50%' }}></span>
                    <span className='lato-regular'>Bruto ajustado a Inflación</span>
                </div>
                <div style={{ display: 'inline-block', marginRight: '20px', alignItems: 'center' }}>
                    <span style={{ backgroundColor: '#FFD700', display: 'inline-block', width: '15px', height: '15px', marginRight: '5px', borderRadius: '50%' }}></span>
                    <span className='lato-regular'>Inflación Mensual</span>
                </div>
            </div> */}
            <div style={{ paddingTop: "20px", height: "40em" }} className={darkMode ? "bg-grafica px-5 pb-4" : "bg-grafica-dark px-5 pb-4"}>
                <Bar data={chartData} options={options} />
            </div>
        </>
    );
};

export default GrafLineasTerminales;