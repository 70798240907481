import React from "react";
import TablaNroComercio from "./TablaNroComercio";

const ContenidoAdminNroComercio = () => {

    return (
        <div className="container">
            <div>
                <TablaNroComercio />
            </div>
        </div>
    );
};

export default ContenidoAdminNroComercio;
