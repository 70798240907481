import React from "react";
import TablaListaConsultaAliados from "./TablaListaConsultaAliados";

const ContenidoAdminPeticiones = () => {
  return (
    <div className="container">
      <TablaListaConsultaAliados />
    </div>
  );
};

export default ContenidoAdminPeticiones;
