import React from "react";
import TablaLegajos from "./TablaLegajos";

const ContenidoAdminLegajos = () => {

  return (
    <div className="container">
      <div>
        <TablaLegajos />
      </div>
    </div>
  );
};

export default ContenidoAdminLegajos;
