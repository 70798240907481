import React from "react";

import TerminalAdministrador from "./TerminalAdministrador";

const ContenidoFormulariosAdministradores = () => {
  return (
    <div>
      <TerminalAdministrador
      />
    </div>
  );
};

export default ContenidoFormulariosAdministradores;
